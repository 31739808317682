import React from 'react';
import Layout from '../layout/layout';
import Container from '../components/container';

const FourOFour = () => (
  <Layout>
    <Container>
      <h1>404</h1>
      <p>Page not found</p>
    </Container>
  </Layout>
);

export default FourOFour;
